import { useState, useEffect } from "react"

let passiveSupported = false

try {
  const options = {
    get passive() {
      // This function will be called when the browser
      //   attempts to access the passive property.
      passiveSupported = true
      return false
    },
  }

  window.addEventListener("test", null, options)
  window.removeEventListener("test", null, options)
} catch (err) {
  passiveSupported = false
}

export function useTouchEvents() {
  const [hasTouch, setHasTouch] = useState(false)
  const [hasMouse, setHasMouse] = useState(false)

  useEffect(() => {
    let mounted = true

    function watchForTouch() {
      if (typeof window !== `undefined`) {
        function enableTouch() {
          document.removeEventListener(
            "touchstart",
            enableTouch,
            passiveSupported ? { passive: true } : true
          )
          document.removeEventListener(
            "mousemove",
            disableTouch,
            passiveSupported ? { passive: true } : true
          )
          setHasTouch(true)
        }

        function disableTouch() {
          document.removeEventListener(
            "touchstart",
            enableTouch,
            passiveSupported ? { passive: true } : true
          )
          document.removeEventListener(
            "mousemove",
            disableTouch,
            passiveSupported ? { passive: true } : true
          )
          setHasMouse(true)
        }

        document.addEventListener(
          "touchstart",
          enableTouch,
          passiveSupported ? { passive: true } : true
        )
        document.addEventListener(
          "mousemove",
          disableTouch,
          passiveSupported ? { passive: true } : true
        )
      }
    }

    watchForTouch()
    return () => {
      mounted = false
      // watchForTouch()
    }
  }, [])

  return { hasTouch: hasTouch ? true : hasMouse ? false : null }
}
