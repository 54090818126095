import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { gql } from "@apollo/client"
import { useQuery } from "@apollo/client"

import ProductCard, {
  Container as ProductCardContainer,
} from "./ProductCard/ProductCard"
import FilterSideBar from "./FilterSideBar/FilterSideBar"
import breakPoints from "../../APIs/devices"
import SEO from "../seo"

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  max-width: 1600px;
  margin: var(--header-height-mobile) auto 4rem;
  padding: 0 20px;
  @media only screen and (min-width: ${breakPoints.lg}) {
    padding: 3rem 20px;
    margin: var(--header-height) auto 0;
  }
`

const PageBannerContainer = styled.div`
  display: none;
  @media only screen and (min-width: ${breakPoints.md}) {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 20%;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 2rem;
  }
`

const PageBannerWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
`

const PageBannerImage = styled.img`
  width: 100%;
  margin: 0;
  height: 100%;
  object-fit: cover;
`

const PageBannerDescription = styled.h1`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  color: white;
  padding-bottom: 3rem;
`

const PageHeader = styled.h1`
  position: relative;
  @media only screen and (min-width: ${breakPoints.md}) {
    display: none;
  }
  @media only screen and (min-width: ${breakPoints.lg}) {
    font-size: 3vw;
    margin: 0 0 2rem 0;
  }
`

const ProductsContainer = styled.section`
  position: relative;
  flex: 1;
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 1200px;
`

const ProductsGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  @media only screen and (min-width: ${breakPoints.lg}) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-row-gap: 40px;
    grid-column-gap: 30px;
  }
`

const LoadMoreContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`

const LoadMore = styled.button`
  color: white;
  background-color: var(--ruelo-dark-purple);
  padding: 16px 24px;
  border: none;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: scale(0.97);
  }
`

const GET_COLLECTION = gql`
  query Titles($handle: String!, $after: String) {
    collection(handle: $handle) {
      id
      title
      description
      image {
        url(transform: { maxWidth: 1000 })
      }
      products(first: 18, after: $after) {
        edges {
          node {
            availableForSale
            id
            title
            tags
            variants(first: 10) {
              edges {
                node {
                  id
                  title
                  price {
                    amount
                  }
                }
              }
            }
            handle
            productType
            images(first: 1) {
              edges {
                node {
                  id
                  url(transform: { maxHeight: 500 })
                }
              }
            }
            # metafields(first: 1, namespace: "background-colour") {
            #   edges {
            #     node {
            #       namespace
            #       value
            #       key
            #     }
            #   }
            # }
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`
const bgcolors = [
  "var(--ruelo-light-purple)",
  "var(--ruelo-beige)",
  "var(--ruelo-grey)",
]

let prevColor = 3

const CollectionsPage = ({ collectionHandle = "" }) => {
  const { loading, error, data, fetchMore } = useQuery(GET_COLLECTION, {
    variables: { handle: collectionHandle },
  })

  const handleFetchMore = () => {
    const { collection } = data
    const { products } = collection

    fetchMore({
      query: GET_COLLECTION,
      variables: {
        handle: collectionHandle,
        after: products.edges[products.edges.length - 1].cursor,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const prevProducts = prevResult.collection.products.edges
        const newProducts = fetchMoreResult.collection.products.edges
        const newPageInfo = fetchMoreResult.collection.products.pageInfo

        return {
          ...prevResult,
          collection: {
            ...prevResult.collection,
            products: {
              ...prevResult.collection.products,
              edges: [...prevProducts, ...newProducts],
              pageInfo: newPageInfo,
            },
          },
        }
      },
    })
  }

  if (error) {
    console.error(`Error occured at collections page: ${error}`)
    navigate("/error")
    return null
  }

  if (data && data.collection === null) {
    navigate("404")
    return null
  } else if (loading) {
    return (
      <Container>
        <SEO title="Collections" />
        <FilterSideBar />
        <ProductsContainer>
          <PageHeader
            style={{
              backgroundColor: "var(--grey)",
              color: "var(--grey)",
              borderRadius: "20px",
            }}
          >
            ...
          </PageHeader>

          <ProductsGrid>
            {[...Array(6)].map((item, index) => (
              <ProductCardContainer
                key={index}
                bgcolor="var(--grey)"
                $isLoading={loading}
              />
            ))}
          </ProductsGrid>
        </ProductsContainer>
      </Container>
    )
  } else if (data) {
    if (data.collection === null) {
      navigate("404")
    } else {
      const { title, products, image, description } = data.collection

      return (
        <>
          <Container>
            <SEO
              title={title === "All" ? "The Whole Collection" : title}
              keywords={[
                "Ruelo Patisserie",
                title === "All" ? "The Whole Collection" : title,
              ]}
            />
            <FilterSideBar />
            <ProductsContainer>
              {image && (
                <PageBannerContainer>
                  <PageBannerWrapper>
                    <PageBannerImage src={image?.url} />
                    {description && (
                      <PageBannerDescription>
                        {description}
                      </PageBannerDescription>
                    )}
                  </PageBannerWrapper>
                </PageBannerContainer>
              )}
              <PageHeader>
                {title === "All" ? "The Whole Collection" : title}
              </PageHeader>

              <ProductsGrid>
                {products?.edges.map(({ node: product }) => {
                  const { metafields } = product
                  let color
                  //Metafields has been changed some time. It no longer has edges. Not fixing this for now. This currently doesn't work. Written: July 7 2023

                  if (metafields?.edges.length > 0) {
                    const { value } = metafields.edges[0].node
                    /*
                    purple = 0
                    brown = 1
                    grey = 2
                    */
                    switch (value) {
                      case "grey": {
                        color = 2
                        break
                      }
                      case "gray": {
                        color = 2
                        break
                      }
                      case "purple": {
                        color = 0
                        break
                      }
                      case "brown": {
                        color = 1
                        break
                      }
                      default:
                        color = 0
                    }

                    prevColor = color
                  } else {
                    color = Math.floor(Math.random() * Math.floor(3))

                    if (color === prevColor) {
                      color = (prevColor + 1) % 2
                    }

                    prevColor = color
                  }

                  return (
                    <ProductCard
                      key={product.id}
                      product={product}
                      bgcolor={bgcolors[color]}
                    />
                  )
                })}
              </ProductsGrid>

              {products.pageInfo.hasNextPage && (
                <LoadMoreContainer>
                  <LoadMore onClick={() => handleFetchMore()}>
                    Load More
                  </LoadMore>
                </LoadMoreContainer>
              )}
            </ProductsContainer>
          </Container>
        </>
      )
    }
  } else {
    return null
  }
}

export default CollectionsPage
