import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"
import { FaPlus } from "react-icons/fa"

import Label from "../../Collections/ProductCard/Label"
import NoImage from "../../../images/no_image.png"
import { setProductPageColor } from "../../../actions/setProductPageBlogColor"
// import { UnavailableCurtain } from "../../Product/Cakes/SizeSection"
import { useTouchEvents } from "../../../APIs/hasTouch"
import { addProductToCart } from "../../../actions/setCart"
import { addToCartPopupOn } from "../../../actions/toggleAddToCartPopup"

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 130%;
  margin-bottom: 30px;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 200ms ease-in-out;
  &:active {
    transform: scale(0.97);
  }
  ${({ $hasTouch }) =>
    !$hasTouch &&
    `
      &:hover {
        transform: scale(1.02);
    }
  `}
  ${({ $isloading }) => {
    if ($isloading) {
      return `
        pointer-events: none;`
    }
  }}
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ bgcolor }) => bgcolor};
  opacity: 0.6;
`

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const ImageContainer = styled.div`
  position: relative;
  margin: 0 auto;
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $hasTouch }) =>
    !$hasTouch &&
    `
    &:hover {
      cursor: pointer;
    }
  `}
`
export const WhiteFooter = styled.div`
  position: absolute;
  height: 40%;
  bottom: 0;
  width: 100%;
  background-color: white;
`

const Image = styled.img`
  position: relative;
  width: 75%;
  height: 75%;
  object-fit: contain;
  margin: 0;
  margin-top: 3rem;
`

const QuickAddButton = styled.button`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 70px;
  height: 70px;
  padding-top: 4px;
  border-radius: 70px;
  border: none;
  background-color: ${({ bgcolor }) => bgcolor};
  /* opacity: 0.6; */
  &:active {
    transform: scale(0.97);
  }
  &:hover {
    cursor: pointer;
  }
`

const Plus = styled(FaPlus)`
  color: white;
  width: 50%;
  height: 50%;
`

const ProductCard = ({ bgcolor, loading, product }) => {
  //Redux
  const dispatch = useDispatch()
  const { $hasTouch } = useTouchEvents()

  const [showQuickAdd, setShowQuickAdd] = useState(true)

  const quickAddRef = useRef(null)

  useEffect(() => {
    let mounted = true
    if (product && mounted) {
      if (
        product.productType === "Macarons" ||
        product.productType === "Mini Cakes"
      ) {
        setShowQuickAdd(true)
      } else {
        setShowQuickAdd(false)
      }
    }

    return () => (mounted = false)
  }, [product])

  if (loading) {
    return <Container bgcolor="var(--grey)" $isloading={loading} />
  } else {
    const {
      title,
      productType,
      handle,
      images,
      variants,
      availableForSale,
    } = product

    const handleRedirect = async () => {
      const result = await new Promise(resolve =>
        dispatch(setProductPageColor(bgcolor, resolve))
      )
      navigate(`/products/${handle}`)
    }

    return (
      <Container
        bgcolor={bgcolor}
        $hasTouch={$hasTouch}
        onClick={e => {
          if ($hasTouch) {
            if (
              quickAddRef === null ||
              !quickAddRef?.current?.contains(e.target)
            ) {
              handleRedirect()
            }
          }
        }}
      >
        <Background bgcolor={bgcolor} />
        <Wrapper>
          {images.edges.length > 0 ? (
            <ImageContainer $hasTouch={$hasTouch} onClick={handleRedirect}>
              {/* <WhiteFooter /> */}
              <Image
                src={images.edges[0].node.url}
                alt={`${title} product image`}
              />
            </ImageContainer>
          ) : (
            <ImageContainer onClick={handleRedirect}>
              {/* <WhiteFooter /> */}
              <Image src={NoImage} alt="no image available" />
            </ImageContainer>
          )}
          <Label
            title={title}
            handle={handle}
            productType={productType}
            variants={variants}
            bgcolor={bgcolor}
            showQuickAdd={showQuickAdd}
          />
        </Wrapper>
        {showQuickAdd && (
          <QuickAddButton
            ref={quickAddRef}
            alt="quick-add-button"
            bgcolor={bgcolor}
            onClick={() => {
              dispatch(
                addProductToCart({
                  variantId: variants.edges[0].node.id,
                  customAttributes: {},
                })
              )
              dispatch(addToCartPopupOn())
            }}
          >
            <Plus />
          </QuickAddButton>
        )}
        {/* {!availableForSale && (
          <UnavailableCurtain hover={true} onClick={handleRedirect} />
        )} */}
      </Container>
    )
  }
}

export default ProductCard
