import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { gql } from "@apollo/client"
import { useQuery } from "@apollo/client"

const StyledLink = styled(Link)`
  position: relative;
  color: black;
  text-decoration: none;
  &:hover {
    color: var(--secondary-color);
  }
`
const List = styled.ul`
  position: relative;
  list-style: none;
`
const ListItem = styled.li`
  position: relative;
`

const GET_COLLECTIONS = gql`
  {
    collections(first: 100, query: "-title:All", sortKey: TITLE) {
      edges {
        node {
          id
          handle
          title
        }
      }
    }
  }
`

const BrowseLinks = () => {
  const { loading, error, data } = useQuery(GET_COLLECTIONS)

  if (error) {
    console.error(`Error occured at browse links ${error}`)
    navigate("/error")
    return null
  }

  return (
    <>
      <List>
        <ListItem>
          {loading && `Loading...`}
          {data && (
            <>
              <StyledLink to="/shop/all">All Products</StyledLink>
              <List>
                {data.collections.edges.map(edge => {
                  const {
                    node: { title, handle },
                  } = edge

                  return (
                    <ListItem key={handle}>
                      <StyledLink to={`/shop/${handle}`}>{title}</StyledLink>
                    </ListItem>
                  )
                })}
              </List>
            </>
          )}
        </ListItem>
      </List>
    </>
  )
}

export default BrowseLinks
