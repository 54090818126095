import React from "react"
import styled from "styled-components"

import breakPoints from "../../../APIs/devices"
import BrowseLinks from "./BrowseLinks"

const Container = styled.aside`
  display: none;
  height: fit-content;
  border: 2px solid var(--grey);
  border-radius: 30px;
  padding: 2rem 2rem 4rem;
  position: relative;
  width: 300px;
  margin-right: 2rem;
  margin-top: 2rem;
  padding-left: 30px;
  @media only screen and (min-width: ${breakPoints.lg}) {
    display: unset;
  }
`

const Header = styled.h2`
  position: relative;
`

const FilterSideBar = () => {
  return (
    <Container>
      <Header>Browse By:</Header>
      <hr />
      <BrowseLinks />
    </Container>
  )
}

export default FilterSideBar
