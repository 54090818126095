import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { useDispatch } from "react-redux"

import breakPoints from "../../../APIs/devices"
import { FlexContainer, FlexItem } from "../../FlexGrid/FlexGrid"
import { setProductPageColor } from "../../../actions/setProductPageBlogColor"

const Container = styled(FlexContainer)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border-radius: 0 0 30px 30px;
  padding: 1rem 2rem;
`
const ProductNameContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  margin: 1.5rem 2rem 0rem;
`

const ProductName = styled.h3`
  position: relative;
  margin: 0;
  width: 85%;
  white-space: initial;
  text-align: left;
  padding: 0;
  font-size: 1.4rem;
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
  }
`

const CollectionTitle = styled.div`
  color: white;
`

const PriceContainer = styled(FlexItem)`
  position: relative;
  flex-direction: column;
`

const PriceWrapper = styled(FlexItem)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ProductPrice = styled.h3`
  position: relative;
  margin: 0;
  overflow: hidden;
  font-size: 1.4rem;
`

const Description = styled.p`
  position: relative;
  margin: 0;
  color: #aeaeae;
  /* color: white; */
  @media only screen and (max-width: ${breakPoints.lg}) {
    display: none;
  }
`
const ViewMore = styled(FlexItem)`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const Label = ({
  title,
  productType,
  handle,
  bgcolor,
  showQuickAdd,
  variants,
}) => {
  //Redux
  const dispatch = useDispatch()

  //Custom description based on the tags
  const [description, setDescription] = useState("")

  const handleRedirect = async () => {
    const result = await new Promise(resolve =>
      dispatch(setProductPageColor(bgcolor, resolve))
    )
    navigate(`/products/${handle}`)
  }

  useEffect(() => {
    if (productType === "Cakes") {
      setDescription(`${variants.edges.length} sizes available`)
    }
  }, [productType, variants.edges.length])

  return (
    <>
      <ProductNameContainer>
        <ProductName
          className="label-product-name"
          onClick={() => handleRedirect()}
        >
          {title}
        </ProductName>
        <CollectionTitle>{productType}</CollectionTitle>
      </ProductNameContainer>
      <Container className="label-container">
        <PriceContainer container xs={6}>
          <PriceWrapper>
            <ProductPrice>
              ${`${Number(variants.edges[0].node.price.amount).toFixed(2)}`}
            </ProductPrice>
            <Description>{description}</Description>
          </PriceWrapper>
        </PriceContainer>
        {!showQuickAdd && (
          <ViewMore xs={6} onClick={() => handleRedirect()}>
            view more
          </ViewMore>
        )}
      </Container>
    </>
  )
}

export default Label
