export const setProductPageColor = (color, resolve) => dispatch => {
  let newColor
  switch (color) {
    case "var(--ruelo-grey)": {
      newColor = "grey"
      break
    }
    case "var(--ruelo-beige)": {
      newColor = "beige"
      break
    }
    default: {
      newColor = "light-purple"
    }
  }

  dispatch(setColor(newColor))

  if (resolve) {
    return resolve()
  }
}

export const setColor = color => ({
  type: "SET_PRODUCT_PAGE_BLOB_COLOR",
  color,
})
